<template>
    <CCard>
        <CCardHeader>
            <strong>&nbsp; &nbsp; General Settings </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
        </CCardHeader>
        <CCardBody>
            <CForm>
                <CInput
                        description="Network Name"
                        label="Wlan Name"
                        horizontal
                        id="name"
                        type="text"
                        :value="wl.name"
                        disabled
                />

                <CInput
                        description="Network visible name"
                        label="Wlan SSID"
                        horizontal
                        id="ssid"
                        type="text"
                        :value="wl.ssid"
                        disabled
                />

                <CInput
                        description="Password to access network"
                        label="Wlan Passphrase"
                        horizontal
                        id="passphrase"
                        type="text"
                        :value="wl.encryption.passphrase"
                        disabled
                />

                <CInput
                        description="Traffic ID on switch to access internet"
                        label="Vlan ID"
                        horizontal
                        id="vlan"
                        type="text"
                        :value="wl.vlan.accessVlan"
                        disabled
                />


            </CForm>
        </CCardBody>

        <CCardHeader>
            <strong>&nbsp; &nbsp; Hosted WLANs Info </strong> <span
                style="color: red; padding-bottom: -30px; font-size: 10px"> (db) </span> Edit
            <div v-if="hostedNetworks" class="lds-ripple">
                <div></div>
                <div></div>
            </div>
            <CButton v-if="!hostedNetworks" type="submit" size="sm" @click="getMembership()" color="info">
                🔄 with controller
            </CButton>
        </CCardHeader>
        <CCard>
            <CCardHeader>
                <CIcon name="cil-justify-center"/>
                <strong> WLAN Membership </strong><small></small>
            </CCardHeader>
            <CCardBody>
                <CListGroup>
                    <template v-for="member in membership">
                        <CListGroupItem href="#"
                                        class="flex-column align-items-start">
                            <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1">{{member.name}}</h5>
                            </div>

                            <p class="mb-1">
                                WLAN Id: {{member.id}} <br>
                                Zone Id: {{member.zoneId}} <br>
                                WG Name: {{member.name}} <br>
                            </p>
                            <p style="color: red; font-size: 14px" v-if="static_mac === getApMacFromWG(member.name)">
                                static shoudn't be removed, right? :) </p>

                            <CCol v-if="static_mac !== getApMacFromWG(member.name)" sm="2" class="mb-3 mb-xl-0">
                                <CButton block color="danger" @click="removeWG(wl.id, member.zoneId, member.id)"
                                         shape="pill">Remove
                                </CButton>
                            </CCol>

                        </CListGroupItem>
                    </template>
                </CListGroup>

            </CCardBody>
        </CCard>
        <CCard>
            <CCardHeader>
                <CIcon name="cil-justify-center"/>
                <strong> Emergency WLAN </strong><small></small>
            </CCardHeader>
            <p style="margin-left: 30px; margin-top: 30px"> Host this WLAN on selected unit </p>
            <CCardBody>
                <CDataTable
                        hover
                        sorter
                        columnFilter
                        itemsPerPageSelect
                        responsive
                        :items="items"
                        :fields="fields"
                        :items-per-page="5"
                        clickable-rows
                        :active-page="activePage"
                        @row-clicked="rowClicked"
                        :pagination="{ doubleArrows: true, align: 'center'}"
                        @page-change="pageChange"
                >
                </CDataTable>

                <CCardFooter>
                    <div v-if="selectedFlag" class="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>

                    <CButton @click="hostWlan()" type="submit" size="sm" color="primary">
                        <CIcon name="cil-check-circle"/>
                        Host Wlan
                    </CButton>
                </CCardFooter>

            </CCardBody>
        </CCard>
    </CCard>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'Wlan',
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('accesspoints')
            })
        },
        data() {
            return {
                selectOptions: [
                    'Option 1', 'Option 2', 'Option 3', 'Option 1', 'Option 2', 'Option 3', 'Option 1', 'Option 2', 'Option 3',
                    {
                        value: 'some value',
                        label: 'Selected option'
                    }
                ],
                selectedOption: 'some value',

                items: null,
                selected_item: null,
                selectedFlag: false,
                fields: [
                    {key: 'zone_name', value: "Zone Name"},
                    {key: 'bul_name', value: "Building"},
                    {key: 'uni_name', value: "Unit"},
                    {key: 'rap_mac', value: "AP Mac"},
                ],
                usersOpened: null,
                rctl_id: null,
                wl: null,
                membership: null,
                ctrlSyncFlagAP: false,
                hostedNetworks: false,
                static_mac: null,
                ap_members: {
                    'members_24': [],
                    'members_50': []
                },
                vxur: false,
                index: -14000,
            }
        },
        methods: {
            goBack() {
                this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/accesspoints'})
            },
            capitalizeFirstLetter(st) {
                var str_split = st.split('_')
                var new_array = []

                for (var ch = 0; ch < str_split.length; ch++) {
                    var newstr = str_split[ch].charAt(0).toUpperCase() + str_split[ch].slice(1);
                    new_array.push(newstr)
                }

                return new_array.join(' ')
            },
            rowClicked(item) {
                this.selected_item = item;
            },
            hostWlan() {
                console.log(this.selected_item);
                this.selectedFlag = true;

                axios.post(this.$baseURL + '/ruckus/wl/wg_add', {
                    selected_item: this.selected_item,
                    data: this.wl
                })
                    .then((res) => {
                        res;
                        this.selectedFlag = false;
                        this.$toast.success(`Successfully hosted ${this.wl.name} on ${this.selected_item.rap_mac}`)

                    }).catch((error) => {
                    this.$toast.error(error);
                })

                this.selectedFlag = false;

            },
            removeWG(wl_id, zone_id, wg_id) {
                console.log(wl_id, zone_id, wg_id);

                axios.delete(this.$baseURL + '/ruckus/wl/wg_remove?wl_id=' + wl_id +
                    '&zone_rid=' + zone_id + '&wg_id=' + wg_id + '&rctl_id=' + this.rctl_id).then(
                    (res) => {
                        res;
                        this.$toast.success(`Successfully removed ${this.wl.name} from ${wg_id}`);
                    }
                ).catch((error) => {
                    error;
                    this.$toast.error(`Faled to remove ${this.wl.name} from ${wg_id}`);
                })

            },
            wlanQuery() {
                this.hostedNetworks = true;
                this.$toast.info("Started fetching data from controller... <br> this may take a while")
                this.static_mac = this.$route.params.static_mac
                const wlan_id = this.$route.params.wlan_id
                this.rctl_id = parseInt(this.$route.params.rctl_id)
                const zone_rid = this.$route.params.zone_rid
                this.ctrlSyncFlagAP = true;
                axios.get(this.$baseURL + '/ruckus/wl/details?wl_id=' + wlan_id + '&rctl_id=' + this.rctl_id + '&zone_rid=' + zone_rid, this.ap)
                    .then((res) => {
                        this.wl = res.data;
                        this.$toast.success("Successfully synced WLAN with controller");
                        this.hostedNetworks = false;
                    }).catch((error) => {
                    this.hostedNetworks = false;
                    this.$toast.error("Failed to sync WLAN due to " + error);
                    console.log(error);
                })
            },

            getMembership() {
                this.hostedNetworks = true;
                this.$toast.info("Started fetching data from controller... <br> this may take a while")
                this.static_mac = this.$route.params.static_mac
                const wlan_id = this.$route.params.wlan_id
                this.rctl_id = parseInt(this.$route.params.rctl_id)
                const zone_rid = this.$route.params.zone_rid
                this.ctrlSyncFlagAP = true;
                axios.get(this.$baseURL + '/ruckus/wl/membership?wl_id=' + wlan_id + '&rctl_id=' + this.rctl_id + '&zone_rid=' + zone_rid, this.ap)
                    .then((res) => {
                        this.membership = res.data;
                        this.$toast.success("Successfully fetched WLAN membership");
                        this.hostedNetworks = false;
                    }).catch((error) => {
                    this.hostedNetworks = false;
                    this.$toast.error("Failed to sync WLAN membership " + error);
                    console.log(error);
                })
            },
            getWgs() {

                axios.get(this.$baseURL + '/ruckus/wl/zone_wgs?zone_rid=' + this.$route.params.zone_rid)
                    .then(
                        (res) => {
                            this.items = res.data;
                            this.$toast.success("Successfully fetched zone WGs");
                        }
                    ).catch((error) => {
                    this.toast.error("Failed to fetch zone wgs" + error);
                })
            },

            getById(id) {
                return document.getElementById(id)._value
            },
            getApMacFromWG(wg) {

                var wg_split = wg.split('_');
                var ap_mac = wg_split.slice(-1).pop();
                console.log(ap_mac)
                return ap_mac
            },
        },
        mounted() {
            this.validateSession();
            this.wlanQuery();
            this.getWgs();

        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 36px;
            left: 36px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 0px;
            left: 0px;
            width: 72px;
            height: 72px;
            opacity: 0;
        }
    }


</style>